header {
    width: 100%;
    position: sticky;
    top: 0px;
    display: flex;
    gap: var(--size-lg);
    justify-content: flex-end;
    padding: var(--size);
    z-index: var(--overlay-index);
    transition: top 0.2s ease-in-out;
}

header > a {
    font-size: var(--size-lg);
    color: var(--accent-color);
    text-decoration: none;
}

@media only screen and (max-width: 767.9px)   {
    header > a {
        font-size: var(--size-md);
    }
}