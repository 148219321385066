* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Oswald', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--primary-color);
  color: var(--text-color);
}

html, body, #app-wrapper {
  width: 100%;
  height: 100%;
}

html {
  scroll-behavior: smooth;
}

h2 {
  font-family: 'Playfair Display', serif;
}

:root {
  --size-sm: 0.5rem;
  --size: 1rem;
  --size-md: 1.5rem;
  --size-lg: 2rem;
  --size-xl: 5rem;
  --size-2xl: 10rem;
  --size-3xl: 15rem;
  --size-4xl: 20rem;
  --size-title: 8rem;
  --accent-color: #FF8A00;
  --primary-color: #00194A;
  --text-color: #fff;
  --overlay-index: 3;
  --cubic: cubic-bezier(0.165, 0.84, 0.44, 1);
}

@media only screen and (max-width: 767.9px)   {
  :root {
    --size-title: 3.5rem;
  }
}