#app {
    width: 100%;
    height: max-content;
    max-width: 1600px;
    margin: 0 auto;
}

#app > .content {
    width: 100%;
    height: max-content;
    overflow: hidden;
}

.content > section {
    width: 100%;
    min-height: calc(100vh - env(safe-area-inset-bottom) - env(safe-area-inset-top));
}

.taskbar {
    display: flex;
    position: absolute;
    width: 100%;
    height: var(--size-lg);
    top: 0;
    left: 0;
    background: var(--accent-color);
    align-items: center;
}

.taskbar.movable {
    cursor: move;
}

.taskbar-controls {
    height: 100%;
    user-select: none;
}


@media only screen and (min-width: 1200px) {
    .content > section {
        min-height: 1000px;
    }
}