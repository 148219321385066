#about {
    position: relative;
    overflow: hidden;
}

.about__title {
    position: absolute;
    margin: 0;
    font-size: var(--size-title);
    font-family: 'Playfair Display', serif;
    letter-spacing: var(--size-md);
    user-select: none;
    z-index: -1;
}

.about__title-row {
    display: flex;
    gap: 3rem;
    white-space: nowrap;
    opacity: 0.1;
}

.about__title-row:first-child {
    opacity: 1;
}

.about__content {
    display: flex;
    padding: var(--size-lg);
    gap: 4rem;
    justify-content: center;
    margin-top: var(--size-3xl);
}

.about__text, .about__img-ctn {
    flex: 0 0 auto;
    height: max-content;
}

.about__text {
    position: relative;
    padding: var(--size-lg);
    background: #fff;
    font-size: var(--size-lg);
    color: var(--primary-color);
    border: 2px solid var(--accent-color);
    width: 50%;
    padding-top: 4rem;
    user-select: none;
}

.about__text .taskbar.movable > .taskbar__title {
    font-size: 0.5em;
    line-height: 0;
    margin-right: 45%;
    margin-left: auto;
}

.about__img-ctn {
    position: relative;
    width: 20%;
    padding: 0;
    box-shadow: 0px 0px 0px 2px var(--accent-color) inset;
    background: #fff;
    transform: scale(0.9);
    transform-origin: top left;
}

.about__img {
    display: block;
    width: 100%;
    height: auto;
    transform: translate(10%, 10%);
    transition: all 0.5s var(--cubic);
}

.about__img-ctn:hover .about__img {
    transform: translate(0, 0);
}

.about__tech {
    padding-top: 4rem;
}

.about__tech > div { 
    display: flex;
    gap: var(--size);
    overflow-x: scroll;
}

.about__tech-icon {
    width: auto;
    height: 4rem;
}


@media only screen and (max-width: 767.9px)   {
    .about__title {
        letter-spacing: var(--size-sm);
    }

    .about__content {
        align-items: center;
        flex-direction: column;
        margin-top: var(--size-xl);
    }

    .about__text {
        width: 100%;
        font-size: var(--size)
    }

    .about__text .taskbar.movable > .taskbar__title {
        display: none;
    }

    .about__img-ctn {
        width: 60%;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1199.9px) {
    .about__content {
        padding: var(--size-lg);
        margin-top: var(--size-2xl);
    }

    .about__text {
        width: 60%;
        font-size: var(--size-md)
    }

    .about__text .taskbar.movable > .taskbar__title {
        display: none;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1199.9px) and (orientation: portrait) {
    
}